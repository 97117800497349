import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
  return ({
    itemContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #ccc',
      padding: '0 2rem',
      textAlign: 'center',
      background: '#DAE3F330',
      borderRadius: '6px',
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      '& img': {
        width: '64px',
      },
      '& h3': {
        fontSize: '1rem',
        color: theme.palette.primary.main,
        margin: '0'
      }
    },
    content:{
        lineHeight:'1.5rem'
    }
  })
})

export default useStyles

