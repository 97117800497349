import React from "react"
import { useQueryParam, StringParam } from "use-query-params"
import { useStaticQuery, Link, graphql } from "gatsby"
import { Grid, IconButton } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Layout from "../components/Layout"
import useStyles from '../styles/portfolioItemPageStyles'
import SEO from '../components/seo'


const ReactMarkdown = require('react-markdown')

const PortfolioItem = () => {
  const classes = useStyles()
  const query = useStaticQuery(graphql`
  {
    allFile(filter: {childMarkdownRemark: {frontmatter: {id: {eq: "portfolio"}}}}) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            title
            seoDescription
            seoTitle
            seoKeywords
            portfolio {
              img {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                  }
                }
              }, 
              description
              id
              subtitle
              title
              cols
              rows
              seoDescription
              seoTitle
              seoKeywords
            }
          }
        }
      }
    }
  }
  `)
  const [id] = useQueryParam("id", StringParam);
  const portfolioItems = query.allFile.nodes[0].childMarkdownRemark.frontmatter.portfolio
  const item = portfolioItems.find((item) => item.id === id)
  const icon = item?.img.childImageSharp.fluid.tracedSVG
  const seoTitle = item?.seoTitle
  const seoDescription = item?.seoDescription
  const seoKeywords = item?.seoKeywords
  const title = item?.title
  const subtitle = item?.subtitle

  return (
    <Layout>
      <SEO 
        title={seoTitle !== undefined ? seoTitle : title} 
        description ={seoDescription !== undefined ? seoDescription : subtitle} 
        keywords ={seoKeywords !== undefined ? seoKeywords : title}
      />
      <Link to='/portfolio'>
        <IconButton color="primary" aria-label="upload picture" component="span">
          <ArrowBackIosIcon />
        </IconButton>
      </Link>
      <Grid container justify="center" alignItems='center'>
        <Grid item xs={12} sm={6}>
          <div className={classes.itemContent}>
            <div>
              <img src={icon} alt={item?.title} title={item?.subtitle} className={classes.image} />
            </div>
            <h3>{item?.title}</h3>
            <p>{item?.subtitle}</p>
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
        <div className={classes.content}>
          <ReactMarkdown source={item?.description} />
        </div>
        </Grid>

       
      </Grid>
    </Layout>
  )
}

export default PortfolioItem
